<template>
  <div
    :class="classes"
    @click="onClick"
    class="v-slider"
  >
    <div
      ref="bar"
      :style="stylesBar"
      class="v-slider__bar"
    >
      <div
        ref="bullet"
        :style="stylesBullet"
        :class="bulletClasses"
        @mousedown="onDragStart"
        @touchstart="onDragStart"
        class="v-slider-bullet"
      >
        <div
          v-if="withLabel && variants[value].hint_text"
          class="v-slider-bullet__label"
        >
          <span v-html="variants[value].hint_text"></span>
        </div>

        <div
          v-if="withText"
          class="v-slider-bullet__text"
        >
          {{ variants[value].text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPos, getRootStyle, getTextColor, throttle } from '@utils/helpers'

export default {
  name: 'VSlider',

  props: {
    value: Number,
    answer: String,
    variants: Array,
    colors: {
      type: [Array, String],
      default: getRootStyle('--color-primary')
    },
    withLabel: Boolean,
    withText: Boolean,
    lineHeight: {
      type: Number,
      default: 4 // px
    },
    bulletSize: {
      type: Number,
      default: 32 // px
    },
    disabled: Boolean,
    placeholder: {
      type: Number,
      default: -1
    },
    isPlaceholder: Boolean
  },

  data () {
    return {
      line: {
        width: null,
        points: {
          step: null,
          widths: []
        }
      },
      bullet: {
        isDragging: false,
        pos: {
          x: null,
          y: null
        }
      }
    }
  },

  computed: {
    classes () {
      return {
        'v-slider_is_draggable': this.bullet.isDragging,
        'v-slider_is_colored': this.colors,
        'v-slider_is_disabled': this.disabled,
        'v-slider_is_placeholder': this.isPlaceholder
      }
    },
    bulletClasses () {
      return {
        'v-slider-bullet_position_start': this.currentIndex <= 0,
        'v-slider-bullet_position_end': this.currentIndex >= this.variantsIndexesLength
      }
    },
    /**
     * Индекс Выбранного варианта
     * @returns {number}
     */
    currentIndex () {
      const index = this.getNumber(this.line.points.widths, this.bullet.pos.x)
      return index === -1
        ? 0
        : index
    },
    currentColor () {
      return typeof this.colors === 'string'
        ? this.colors
        : this.colors[this.value]
    },
    percentComplete () {
      return parseInt(this.line.points.widths[this.currentIndex] / this.line.width * 100)
    },
    stylesBullet () {
      const halfBulletSize = this.bulletSize / 2

      return {
        left: `-${halfBulletSize}px`,
        height: `${this.bulletSize}px`,
        width: `${this.bulletSize}px`,
        transform: `translate(${this.line.points.widths[this.currentIndex]}px, -50%)`,
        backgroundColor: this.currentColor
      }
    },
    stylesBar () {
      return {
        background: this.disabled
          ? 'rgba(var(--color-rgb-primary), 0.1)'
          : `linear-gradient(to right, ${this.currentColor} ${this.percentComplete}%, rgba(var(--color-rgb-primary), 0.1) ${this.percentComplete}%)`,
        height: `${this.lineHeight}px`,
        color: getTextColor(this.currentColor)
          ? '#111111'
          : 'white'
      }
    },
    variantsIndexesLength () {
      return this.variants.length - 1
    }
  },

  watch: {
    value (newValue) {
      if (this.bullet.isDragging || newValue === this.currentIndex) return

      this.setBulletByIndex(newValue)
    },
    variants () {
      this.setPoints()
    }
  },

  mounted () {
    this.setPoints()
    this.bindEvent()
    this.setBullet()
  },

  beforeDestroy () {
    this.unbindEvent()
  },

  methods: {
    /**
     * Вычисление ближайшего к числу индекса
     * @param {number[]} arr - массив чисел
     * @param {number} searchNum - число для поиска
     * @return {number} - ближайший к чеслу индекс
     */
    getNumber (arr, searchNum) {
      return arr.findIndex(
        item => Math.abs(item - searchNum) === Math.min(...arr.map(item => Math.abs(item - searchNum))))
    },

    onClick (e) {
      e.preventDefault()
      const bulletPos = getPos(e, this.$el)

      this.setBulletByPosition(bulletPos)
      this.$emit('click', this.currentIndex)
    },

    onDragMove (e) {
      if (this.bullet.isDragging) {
        const bulletPos = getPos(e, this.$el)

        e.preventDefault()

        this.setBulletByPosition(bulletPos)
        this.$emit('drag-move', this.currentIndex)
      }
    },

    onDragStart () {
      if (this.bullet.isDragging) return

      this.bullet.isDragging = true
      this.$emit('drag-start', this.currentIndex)
    },

    onDragEnd () {
      if (!this.bullet.isDragging) return

      this.bullet.isDragging = false
      this.$emit('drag-end', this.currentIndex)
    },

    onResize () {
      this.setPoints()
      this.setBullet()
    },

    setBulletByPosition (bulletPos) {
      if (bulletPos.x <= 0) {
        bulletPos.x = 0
      } else if (bulletPos.x >= this.line.width) {
        bulletPos.x = this.line.width
      }

      this.bullet.pos = bulletPos
      this.$emit('input', this.currentIndex)
    },

    setBullet () {
      const startIndex = this.isPlaceholder
        ? this.placeholder
        : this.value

      this.bullet.pos.x = this.line.points.widths[startIndex]
    },

    setBulletByIndex (index) {
      if (this.bullet.pos.x === this.line.points.widths[index]) return

      this.bullet.pos.x = this.line.points.widths[index]
      this.$emit('input', this.currentIndex)
    },

    /**
     * Вычисление ширины линии
     * и расстояния до каждой точки
     */
    setPoints () {
      this.line.width = Math.round(this.$refs.bar.clientWidth)

      const widthStep = +(this.line.width / this.variantsIndexesLength).toFixed(2)

      this.line.points.widths = Array.from({ length: this.variants.length }, (k, l) => +(widthStep * l).toFixed(2))
    },

    bindEvent () {
      document.addEventListener('touchmove', throttle(this.onDragMove, 30), { passive: false })
      document.addEventListener('touchend', this.onDragEnd, { passive: false })
      document.addEventListener('mousemove', throttle(this.onDragMove, 30))
      document.addEventListener('mouseup', this.onDragEnd)
      document.addEventListener('mouseleave', this.onDragEnd)
      window.addEventListener('resize', this.onResize)
    },

    unbindEvent () {
      document.removeEventListener('touchmove', this.onDragMove)
      document.removeEventListener('touchend', this.onDragEnd)
      document.removeEventListener('mousemove', this.onDragMove)
      document.removeEventListener('mouseup', this.onDragEnd)
      window.removeEventListener('resize', this.onResize)
    }
  }
}
</script>

<style lang="scss">
@import "~@styles/variables";
@import "~@styles/tools";

.v-slider {
  display: flex;
  width: 100%;
  user-select: none;
  cursor: pointer;

  &__bar {
    position: relative;
    width: 100%;
    height: 4px;
    margin: 1rem 0;
    border-radius: 1000px;
    background-color: #E9EFF4;
    user-select: none;

    &::before,
    &::after {
      @include radius(pill);
      background-color: $primary;
      content: '';
      display: flex;
      height: 1rem;
      position: absolute;
      top: calc(-.5rem + 2px);
      width: 1rem;
    }

    &::before {
      left: -.5rem;
    }

    &::after {
      right: -.5rem;
    }
  }

  &_is {
    &_draggable {
      .v-slider-bullet {
        &::before {
          transform: translate(-50%, -50%) scale(1.3);
        }
      }
    }

    &_disabled {
      cursor: default;

      .v-slider-bullet {
        display: none;
      }
    }

    &_placeholder {
      .v-slider__bar {
        background: clha($primary, .08) !important;
      }

      .v-slider-bullet {
        background-color: clha($primary, .08) !important;
        border: 0.25rem solid clha($primary, .5);

        &__label {
          display: none;
        }

        &__text {
          display: none;
        }
      }
    }
  }
}

.v-slider-bullet {
  $this: &;

  @include radius(pill);
  align-items: center;
  background-color: clha($primary, .5);
  cursor: grab;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  user-select: none;
  z-index: 1;

  &:hover {
    &::before {
      transform: translate(-50%, -50%) scale(1.3);
    }

    #{$this}__label {
      display: block;
    }
  }

  &::before {
    z-index: -1;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: inherit;
    opacity: .3;
    transition: transform .2s ease;
  }

  &__label {
    display: none;
    @include radius(sm);
    background-color: clha($primary, .1);
    bottom: 40px;
    color: cl(text-primary);
    max-width: 80vw;
    font-size: .75rem;
    padding: .2rem .7rem;
    position: absolute;
  }

  &__text {
    font-size: .75rem;
    font-weight: 600;
  }

  &_position {
    &_start {
      #{$this}__label {
        left: 0;
      }
    }

    &_end {
      #{$this}__label {
        right: 0;
      }
    }
  }
}
</style>
