export default {
  data () {
    return {
      minMaxSendDataPattern: sendDataValue => sendDataValue
    }
  },

  computed: {
    isMultipleMax () {
      return (this.sendData[this.content.code] && this.content.multiple_max > 0) &&
        this.content.multiple_max < this.minMaxSendDataPattern(this.sendData[this.content.code]).length
    },
    multiple_isGreaterOrEqual () {
      return (this.sendData[this.content.code] && this.content.multiple_max > 0) &&
        this.content.multiple_max <= this.minMaxSendDataPattern(this.sendData[this.content.code]).length
    },
    isMultipleMin () {
      return (this.sendData[this.content.code] && this.content.multiple_min > 0) &&
        this.content.multiple_min > this.minMaxSendDataPattern(this.sendData[this.content.code]).length
    }
  },

  methods: {
    checkMultipleMinMax () {
      const { multiple_min, multiple_max } = this.content
      if (multiple_min !== 0 && this.isMultipleMin) {
        this.service.validation.state[this.content.code] = !this.content.required
        this.service.validation.filled[this.content.code] = false
        this.service.error.text = this.$tc('mixins.validatable.multiple.min', multiple_min)
        return false
      }

      if (multiple_max !== 0 && this.isMultipleMax) {
        this.service.validation.state[this.content.code] = !this.content.required
        this.service.validation.filled[this.content.code] = false
        this.service.error.text = this.$tc('mixins.validatable.multiple.max', multiple_max)
        return false
      }

      return true
    }
  }
}
