export const COLORS = [
  'default', 'primary', 'secondary', 'danger',
  'success', 'warning', 'light', 'dark'
]
export const BUTTON_TYPES = ['button', 'submit', 'reset']
export const EL_SIZES = ['8', '12', '14', '16', '18', '24', '32', '40']
export const SIZES = ['xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl']
export const BREAKPOINTS = ['xs', 'sm', 'md', 'lg', 'xl']
export const POSITION_X = ['left', 'center', 'right']
export const POSITION_Y = ['top', 'middle', 'bottom']

/**
 * Поддерживаемые тенмы
 *
 * Регистр учитывается. Тема добавляет класс в тег body с префиксом theme_
 * и так же в store
 *
 * @type {string[]}
 */
export const THEMES = [
  'default',
  'nlmk',
  'evraz',
  'it-brand',
  'fosagro',
  'habr',
  'gpn'
]
export const DIVIDER_THEMES = ['evraz_grey', 'evraz_black', 'evraz_color_1', 'evraz_color_2']
export const ICONS_SPRITE_NAMES = ['common', 'evraz', 'nlmk']
export const DEFAULT_LOGO_KEY = 'defaultLogo'
export const MODES = [
  // compound
  'frame',

  // single
  'hide-footer',
  'hide-header',
  'full-screen'
]
